<template>
  <el-card
    v-loading="isLoading"
    shadow="always"
    :body-style="{ padding: '0px' }"
  >
    <template #header>
      <el-row type="flex" justify="space-between" align="center">
        <el-col :md="8" :xs="24">
          <el-row type="flex" justify="start">
            <h4>{{ $route.name }}</h4>
          </el-row>
        </el-col>
        <el-col :md="16" :xs="24">
          <el-row type="flex" justify="end">
            <el-dropdown
              @command="(c) => filterBills(c)"
              class="mr-8"
              size="medium"
              trigger="click"
            >
              <el-button type="primary" size="medium">
                Exibir contas por: {{ displayPage || ""
                }}<i class="el-icon-arrow-down el-icon--right"></i>
              </el-button>
              <template #dropdown>
                <el-dropdown-menu>
                  <el-dropdown-item command="Em aberto">
                    <li
                      :class="{
                        'el-dropdown-menu__item': true,
                        'no-padding': true,
                        active: displayPage === 'Em aberto',
                      }"
                    >
                      Em aberto
                    </li>
                  </el-dropdown-item>
                  <el-dropdown-item command="Indiferente">
                    <li
                      :class="{
                        'el-dropdown-menu__item': true,
                        'no-padding': true,
                        active: displayPage === 'Indiferente',
                      }"
                    >
                      Indiferente
                    </li>
                  </el-dropdown-item>

                  <el-dropdown-item command="Vencidas"
                    ><li
                      :class="{
                        'el-dropdown-menu__item': true,
                        'no-padding': true,
                        active: displayPage === 'Vencidas',
                      }"
                    >
                      Vencidas
                    </li></el-dropdown-item
                  >
                  <el-dropdown-item command="Baixadas"
                    ><li
                      :class="{
                        'el-dropdown-menu__item': true,
                        'no-padding': true,
                        active: displayPage === 'Baixadas',
                      }"
                    >
                      Baixadas
                    </li></el-dropdown-item
                  >
                </el-dropdown-menu>
              </template>
            </el-dropdown>
            <el-button
              type="primary"
              class="mb-0"
              icon="el-icon-download"
              :loading="isLoadingDownloadButton"
              size="medium"
              @click="downloadReport()"
            ></el-button>
            <el-button
              type="primary"
              icon="el-icon-s-operation"
              size="medium"
              @click="openFilterBillsModal()"
            ></el-button>
          </el-row>
        </el-col>
      </el-row>
    </template>

    <el-card shadow="never" :body-style="{ padding: '0px' }">
      <div v-if="showTable">
        <div v-for="(bills, cost) in BillsGroupedByCostCenter" :key="cost">
          <h2 class="el-table__subheader_name">{{ cost }}</h2>
          <el-table
            stripe
            show-summary
            :summary-method="getSummaries"
            :data="bills"
            style="width: 100%; z-index: 0"
            :cell-style="() => 'text-align:center;'"
          >
            <el-table-column
              label="código"
              prop="ref"
              width="100"
            ></el-table-column>
            <el-table-column prop="bill.firm.name" label="empresa">
            </el-table-column>
            <el-table-column prop="bill.partner.name" label="fornecedor">
            </el-table-column>

            <el-table-column
              label="adquirido em"
              :formatter="(r) => formatDate(new Date(r?.bill?.bought_at))"
            ></el-table-column>
            <el-table-column
              label="vencimento"
              :formatter="(r) => formatDate(new Date(r?.expires_at))"
            ></el-table-column>
            <el-table-column label="ordem" prop="order"> </el-table-column>
            <el-table-column
              label="valor"
              prop="amount"
              :formatter="(r) => formatCurrency(r.amount)"
            >
            </el-table-column>
            <el-table-column
              label="valor pago"
              prop="amount_paid"
              :formatter="(r) => formatCurrency(r.amount_paid)"
            >
            </el-table-column>
          </el-table>
        </div>
        <el-row v-show="bills?.data?.length">
          <el-col :md="1">
            <p class="summary summary-black">Total:</p>
          </el-col>
          <el-col :md="21"></el-col>
          <el-col :md="2">
            <p class="summary">
              {{
                currencyFormatter.format(
                  bills?.data?.reduce((t, b) => (t += Number(b?.amount)), 0) ||
                    0
                )
              }}
            </p>
          </el-col>
        </el-row>
      </div>
      <el-empty v-else description="Insira filtros para obter o relatório">
        <el-button-group>
          <el-button type="primary" @click="updateFiltersFromDays(60)"
            >Vencimentos nos últimos 60 dias</el-button
          >
          <el-button type="primary" @click="updateFiltersFromDays(30)"
            >Vencimentos nos últimos 30 dias</el-button
          >
          <el-button type="primary" @click="updateFiltersFromDays(15)"
            >Vencimentos nos últimos 15 dias</el-button
          >
          <el-button type="primary" @click="updateFiltersFromDays(7)"
            >Vencimentos nos últimos 7 dias</el-button
          >
        </el-button-group>
      </el-empty>
    </el-card>
    <filter-bills-modal
      :showModal="showFilterBillsModal"
      :filters="filterOptions"
      @close-modal="showFilterBillsModal = false"
      @update-filters="updateFilters"
    ></filter-bills-modal>
  </el-card>
</template>

<script>
import { ElNotification /*ElMessageBox*/ } from "element-plus";
import FilterBillsModal from "./modals/FilterBillsModal.vue";
export default {
  name: "BillsReportByCostPage",
  components: { FilterBillsModal },
  data: () => ({
    hasError: false,
    isLoading: false,
    bills: null,
    bill: null,
    dateFormatter: new Intl.DateTimeFormat("pt-BR", {
      day: "numeric",
      month: "long",
      year: "numeric",
    }),
    referenceDate: new Date(),
    ref: null,
    showTable: false,
    displayPage: "Indiferente",
    displayOptions: {},
    filterOptions: {},
    currentPage: 1,
    showFilterBillsModal: false,
    currencyFormatter: new Intl.NumberFormat("pt-BR", {
      style: "currency",
      currency: "BRL",
    }),
    isLoadingDownloadButton: false,
  }),
  mounted() {
    //this.filterBills("Em aberto");
  },
  watch: {
    currentPage() {
      this.fetchBills();
    },
    SearchQuery() {
      this.fetchBills();
    },
  },
  computed: {
    SearchQuery() {
      return this.$store.state.searchNameQuery;
    },
    HasData() {
      return !!this.bills?.data?.length;
    },
    BillsGroupedByCostCenter() {
      const groupedBills = this.bills?.data?.reduce((t, b) => {
        if (t[this.printCostCenterName(b)])
          t[this.printCostCenterName(b)].push(b);
        else t[this.printCostCenterName(b)] = [b];
        return t;
      }, {});

      return this.orderByCostCenter(groupedBills);
    },
  },
  methods: {
    orderByCostCenter(groupedBills) {
      return Object.keys(groupedBills || {})
        ?.sort()
        ?.reduce((obj, key) => {
          obj[key] = groupedBills[key];
          return obj;
        }, {});
    },
    changeDownloadButtonStatus() {
      this.isLoadingDownloadButton = !this.isLoadingDownloadButton;
    },
    downloadReport() {
      this.changeDownloadButtonStatus();

      ElNotification.info({
        title: "Gerando o relatório",
        message: "Isso pode levar alguns segundos",
      });

      const url = new URL(`${this.$store.state.apiUrl}bills/costs/report`);
      url.search = new URLSearchParams({
        ...(this.filterOptions || {}),
        limit: 999999999999,
      });
      fetch(url, {
        credentials: "include",
        headers: {
          Accept: "blob",
        },
      })
        .then((response) => {
          if (response.ok) return response.blob();
          else throw response.json();
        })
        .then((blob) => {
          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          link.download = `Relatório de Contas.pdf`;
          link.click();
          URL.revokeObjectURL(link.href);
        })
        .catch(async (e) => {
          ElNotification.error({
            title: "Ocorreu um erro",
            message: (await e).message,
          });
        })
        .finally(() => this.changeDownloadButtonStatus());
    },
    updateFiltersFromDays(days) {
      const expires_at_start = new Date();
      expires_at_start.setDate(expires_at_start.getDate() - days);
      expires_at_start.setHours(0, 0);

      this.updateFilters({
        ...(this.filterOptions || {}),
        expires_at_start,
      });
    },
    openFilterBillsModal() {
      this.showFilterBillsModal = true;
    },
    formatCurrency(c) {
      return c ? this.currencyFormatter.format(c) : "R$ 0,00";
    },
    printCostCenterName(p) {
      return p?.bill?.cost?.reference || "desconhecido";
    },
    formatDate(c) {
      if (new Date(`${c}`) != "Invalid Date")
        return new Date(c).toLocaleDateString();
      else return "desconhecido";
    },
    updateFilters(filters) {
      if (filters) this.filterOptions = filters;
      this.fetchBills();
    },
    filterBills(filter) {
      let shouldCallFetch = this.displayPage != filter;

      this.displayPage = filter;
      let _;
      this.displayOptions = {};
      switch (filter) {
        case "Em aberto":
          this.displayOptions.status = "unpaid";
          break;
        case "Vencimento no dia":
          _ = new Date();
          this.displayOptions.status = "unpaid";
          this.displayOptions.expires_at_start = new Date(
            _.setUTCHours(0, 0, 0, 0)
          );
          this.displayOptions.expires_at_end = new Date(
            _.setUTCHours(23, 59, 0, 0)
          );
          break;
        case "Vencidas":
          _ = new Date();
          _.setUTCHours(0, 0, 0, 0);
          this.displayOptions.status = "unpaid";
          this.displayOptions.expires_at_end = _;
          break;
        case "Baixadas":
          this.displayOptions.status = "paid";
          break;
      }
      this.currentPage = 1;
      if (shouldCallFetch) this.fetchBills();
    },
    getSummaries(param) {
      const { columns, data } = param;
      const sums = [];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = "Total";
          return;
        }
        if (index > 5) {
          const values = data.map((item) => Number(item[column.property]));
          if (!values.every((value) => isNaN(value))) {
            sums[index] = this.formatCurrency(
              values.reduce((prev, curr) => {
                const value = Number(curr);
                if (!isNaN(value)) {
                  return prev + curr;
                } else {
                  return prev;
                }
              }, 0) || 0
            );
          } else {
            sums[index] = "";
          }
        }
      });

      return sums;
    },
    fetchBills() {
      this.isLoading = true;
      this.showTable = true;
      const url = new URL(`${this.$store.state.apiUrl}payments`);

      this.filterBills(this.displayPage);

      url.search = new URLSearchParams({
        ...(this.filterOptions || {}),
        ...(this.displayOptions || {}),
        limit: 9999999,
        ordenation: '{ "expires_at": "true" }',
        searchName: this.SearchQuery,
        pagination: this.currentPage,
      });
      fetch(url, {
        credentials: "include",
      })
        .then((response) => {
          if (response.status === 200) return response.json();
          else return response.text();
        })
        .then((json) => (this.bills = json))
        .catch(() => (this.hasError = true))
        .finally(() => (this.isLoading = false));
    },
  },
};
</script>
<style>
.el-card {
  width: 100%;
}
li.active {
  background-color: #409eff;
  color: white;
  padding-left: 17px;
}
li.no-padding {
  margin-left: -17px !important;
  margin-right: -17px !important;
}
.mr-8 {
  margin-right: 8px !important;
}
.cell {
  text-align: center;
}
</style>
